import React, {useState} from 'react'
import { Editor } from '@tinymce/tinymce-react';

const Tinymce = props => {

  const [content, setContent] = useState(props.content)

  let toolbar_buttons = 'undo redo | cut copy paste | bold italic blockquote | bullist numlist | link | code help'

  if(props.extended)
    toolbar_buttons = 'undo redo | cut copy paste | styles bold italic blockquote | bullist numlist | link | image media | visualblocks code help'

  if(props.minimal)
    toolbar_buttons = ''

  return <div style={{width: '79%', marginLeft: '20%'}}>
    <input type="hidden" name={props.name} value={content} />
    <Editor
     tinymceScriptSrc="/tinymce/tinymce.min.js"
     apiKey="akkadfn2r13cdt4d89n2v9o78m2wgdl383vkon4h8v2wkfvg"
     initialValue={props.content}
     init={{
       height: props.height,
       menubar: false,
       inline: props.minimal,
       formats: {
          subtitle1: { block: 'h2' },
          subtitle2: { block: 'h3' },
          signature: { block: 'div', classes: 'figcaption' }
        },
        style_formats: [
          { title: 'Podnaslov 1', format: 'subtitle1' },
          { title: 'Podnaslov 2', format: 'subtitle2' },
          { title: 'Potpis ispod slike', format: 'signature' }
      ],
       plugins: [
         'image', 'link', 'code', 'lists', 'advlist', 'wordcount', 'media', 'help'

       ],
       // external_plugins: {
       //   'abbr': '/tinymce_external_plugins/abbr/plugin.js'
       // },
       content_css: '/tinymce_custom_v1.css',
       quickbars_selection_toolbar: props.minimal ? 'bold italic styleselect' : 'bold italic styleselect | quicklink blockquote',
       quickbars_insert_toolbar: false,
       quickbars_image_toolbar: false,
       toolbar: toolbar_buttons
     }}
     onEditorChange={(editorContent) => setContent(editorContent)}
   />
   </div>
}
export default Tinymce
