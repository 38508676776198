import React from 'react';
import {useDroppable} from '@dnd-kit/core';

function Droppable(props) {
  const {isOver, setNodeRef} = useDroppable({
    id: props.id,
    data: {
      accepts: ['type1', 'type2']
    }
  });
  const style = {
    color: isOver ? 'green' : undefined,
    border: '1px solid red',
    width: '500px',
    height: '1800px',
    float: 'left',
    marginLeft: '50px'
  };
  return (
    <div ref={setNodeRef} style={style}>
      <h3>{props.id}</h3>
      {props.children}
    </div>
  );
}

export default Droppable
