import React from 'react';
import {useDraggable} from '@dnd-kit/core';
import {CSS} from '@dnd-kit/utilities';


function Draggable(props) {

  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: props.id,
    data: {
      type: 'type1',
      from: props.from,
      index: props.index,
      ar_size: props.ar_size
    }
  });
  const style = transform ? {
    transform: CSS.Translate.toString(transform),
    border: '2px solid blue',
    // height: '400px',
    margin: '10px',
    width: '250px'
  } :
  {
    border: '1px solid blue',
    // height: '400px',
    margin: '10px',
    width: '250px'
  }

  let items = []
  switch (props.id) {
    case "articles":
      items = props.articles.slice(0, 5)
    break;
    case "mainarticle":
      items = props.articles.slice(0, 1)
    break;
    case "titlebar":
      items = [{title: props.title}]
    break;
    default:
      items = []
  }

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      <p>{props.articles_cnt}</p>
      <strong>{props.id}</strong>
      {items.map((item, index) =>
        <p key={index}>{item.title}</p>
      )}
    </div>
  );
}
export default Draggable
