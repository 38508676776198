require('lightbox2')
require('jquery-ui-bundle')
require('jquery.cookie')

$(document).ready(function() {
  $("#alert_close img").on("click", function() {
   $(".alert").slideUp(100);
   $.cookie('najboljeknjige_alert_close', 'true', { path: '/' });
 });

  var diff = $(window).width() > 1400 ? 0 : 1440 - $(window).width();
  var subnavigation = []

  handle_navigation = function() {
    if($('.main_navigation').width() > (766 - diff)) {
      subnavigation.push($('.main_navigation').children().last().prop('outerHTML'))
      $('.main_navigation').children().last().remove();
      handle_navigation();
    }
  }

  create_subnavigation = function() {
    var open_subnavigation = false
    subnavigation.forEach(function(s){ if (s.indexOf('active') != -1) {open_subnavigation = true}  });
    var more_class = open_subnavigation === true ? "active" : ""
    $('.main_navigation').append("<div class='more_link'><p class='" + more_class + "'>Više</p><div class='more'>" + subnavigation.reverse().join("") + "</div></div>");
    if(open_subnavigation) {
      $('.more').toggleClass('opend');
    }
  }

  if($(window).width() > 1085) {
    handle_navigation();
    if(subnavigation.length > 0) {
      create_subnavigation();
    }
  }

  $('.hamburger').on('click', function(){
    $('body').toggleClass('navigation_open')
  });

  $('.more_link').on('click', function(){
    $('.more').toggleClass('opend');
  });

  $('.news_letter form').on('submit', function(e) {
    e.preventDefault();
    $.get('/newsletter?email=' + $('#search_news_letter').val(), function(data) {
      if(data.message == 'OK') {
        $('.news_letter .content h2').text("Hvala!")
        $('.news_letter .content p').text("Uspješno ste se prijavili na naš newsletter.")
        $('.news_letter form').hide()
      } else if (data.message == 'exist') {
        $('.news_letter .content h2').text("Hvala!")
        $('.news_letter .content p').text("Već ste se prijavili na naš s ovom adresom newsletter.")
        $('.news_letter form').hide()
      } else {
        $('.news_letter .content h2').fadeOut(200)
        $('.news_letter .content h2').text("Greška!")
        $('.news_letter .content h2').fadeIn(200)
        $('.news_letter .content h2').fadeOut(200)
        $('.news_letter .content h2').fadeIn(200)
        $('.news_letter .content h2').fadeOut(200)
        $('.news_letter .content h2').fadeIn(200)
        $('.news_letter .content p').text("Molimo vas da upišete ispravnu email adresu.")
      }

    })

  });

  $('.cookies-eu button').click(function(e){
    e.preventDefault();
    $.cookie('najboljeknjige_hr_cookie_consented', 'true', { path: '/', expires: 365 });
    $('.cookies-eu').fadeOut();
  });

});
