import React, { useState } from 'react';
import {DndContext, useDraggable, useDroppable} from '@dnd-kit/core';

import Draggable from './homepage_builder/Draggable'
import Droppable from './homepage_builder/Droppable'

function handleDragEnd(activeElems, setActive, inactiveElems, setInactive, articles_cnt, countArticles, event) {
    const {active, over} = event;

    if (over && over.data.current.accepts.includes(active.data.current.type) && active.data.current.from !== over.id) {
      if(over.id == "activeBox") {
        countArticles(articles_cnt + active.data.current.ar_size)
        setInactive(inactiveElems.filter(item => item.name !== active.id));
        setActive([...activeElems, {name: active.id, ar_size: active.data.current.ar_size}]);


      }
      if(over.id == "inactiveBox") {
        countArticles(articles_cnt - active.data.current.ar_size)
        setActive(activeElems.filter(item => item.name !== active.id));
        setInactive([...inactiveElems, {name: active.id, ar_size: active.data.current.ar_size}]);
      }
    }
  }

function HomepageBuilder(props) {
  const [active, setActive] = useState(props.active_items);
  const [inactive, setInactive] = useState(props.inactive_items);
  const [articles_cnt, countArticles] = useState(0);

  return (
    <DndContext onDragEnd={(e) => handleDragEnd(active, setActive, inactive, setInactive, articles_cnt, countArticles, e)}>
      <Droppable id="activeBox">
        {active.map((item, index) =>
          <Draggable key={"active_" + index} id={item.name} ar_size={item.ar_size} index={index} from="activeBox" articles={props.articles} title={props.title} articles_cnt={articles_cnt} />
        )}
      </Droppable>
      <Droppable id="inactiveBox">
        {inactive.map((item, index) =>
          <Draggable key={"inactive_" + index} id={item.name} ar_size={item.ar_size} index={index} from="inactiveBox" articles={[]} title={[]} />
        )}
      </Droppable>
    </DndContext>
  )
}
export default HomepageBuilder
